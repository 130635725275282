<template>
  <div
    class="
      w-full
      text-xs
      font-extralight
      overflow-y-scroll
      max-h-48
      px-4
      whitespace-pre-line
      leading-relaxed
    "
  >
    Tutors should always strive to provide the best service during their
    sessions and while employed by Tutoruu by having a professional yet friendly
    relationship with the tutees. <br /><br />
    <span class="font-semibold">Tardiness:</span> <br />
    in the case of receiving a complaint about a tutor being 15 minutes late to
    his session (online or face to face), They receive a penalty of 10% of their
    booking money.<br /><br />

    <span class="font-semibold">Cancellation of a class:</span><br />
    Any form of last-minute cancellation from the tutor will result in a full
    refund of the session and if the class instruction mode was:<br />

    <li>
      <span class="font-semibold">(online):</span> If the tutor notified the
      client and the university ambassador beforehand, no action should be taken
      unless the ambassador says otherwise for good reason. The ambassador then
      would send a warning email to the tutor. If the tutor cancels more than
      one class after being warned, the tutor’s account may be inactivated for
      ten (10) days. If the tutor cancelled the class without notifying the
      client and/or the ambassador three (3) times, the account gets deactivated
      for the remaining time of the semester.
    </li>

    <li>
      <span class="font-semibold">(face to face):</span> If the tutor notified
      the client and the university ambassador beforehand, no action should be
      taken unless the ambassador says otherwise for good reason. The ambassador
      then would send a warning email to the tutor. If the tutor cancels more
      than one class after being warned, the tutor’s account may be inactivated
      for ten (10) days. If the tutor cancelled the class without notifying the
      client, an investigation will be opened with the Tutoruu Onboarding
      Committee to decide further action to be taken.
    </li>

    <br /><br />
    <span class="font-semibold"
      >Involvement in cheating or any unethical act:</span
    ><br />
    Immediate termination of account.<br /><br />

    <li>
      At Tutoruu we only accept ethical and legal interactions between the tutor
      and the tutee. A zero-tolerance policy is applied to any tutor who
      attempts to violate academic integrity or aids in the violation of
      academic integrity. A violation of academic integrity includes, but is not
      limited to, plagiarism, cheating, and unauthorized collaboration.
    </li>
    <br />
    <span class="font-semibold">Low reviews:</span><br />
    a tutor with a rating lower than 2.5/5 for forty five (45) days gets
    temporarily terminated for the remaining time of the semester. <br /><br />
    <span class="font-semibold">Inappropriate use of language or behavior:</span
    ><br />
    While Tutoruu leaves the formality and the way of communicating flexible to
    what the client and tutor are comfortable with, the tutor receives a warning
    email if the client sends a complaint about this issue. The account may be
    terminated for two (2) weeks along with the third warning.
    <br />
    Communication between the tutor and the tutee should only be pertaining
    logistics regarding the tutoring session solely relating to the study
    material and the meeting spot (i.e., zoom meetings or in-person meetings).

    <br /><br />
    <span class="font-semibold"
      >Appropriate communication between Tutor and Student:</span
    ><br />

    It should be duly noted that tutors are not expected to discuss academic
    material with the tutees outside the premises of their pre-scheduled
    meeting. Should the tutee like to ask questions and/or need more information
    from the tutor, it is encouraged that the tutor propose for them to book
    another session with the tutor at tutoruu.com instead. <br />
    It is not accepted for tutors at Tutoruu to take bookings from outside of
    Tutoruu. This is including but not limited to personal tutoring attempts or
    through a third-party website other than tutoruu.com.
  </div>
</template>

<script>
export default {};
</script>
