<template>
  <credentials-layout title="">
    <transition name="fade-right" appear>
      <div class="lg:mt-0 w-full mb-4 px-2" v-if="on">
        <div class="lg:mx-12 px-4 w-full max-w-3xl text-left">
          <p class="text-3xl font-bold w-full max-w-3xl lg:text-5xl py-2 pr-2">
            Hey <span class="text-orange">{{ user.name.split(' ')[0] }}</span>! Welcome to the tutoruu family &#128035;
          </p>
          <p class="font-extralight">
            Before you begin your application, we would appreciate if you read through our code of conduct. 
            Standardizing the quality of student-lead tutoring sessions is foundational to the supportive, trustworthy community that we are building, and that you are a part of! 
          </p>
        </div>
      </div>
    </transition>
    <transition name="fade" appear>
      <div class="mx-6 my-4" v-if="on">
        <p class="text-lg font-bold">Code of Conduct</p>
        <div class="w-full relative p-4 bg-white h-52 mb-4">
          <codeofconduct/>
        </div>
      </div>
    </transition>
    <div class="px-6 flex items-center justify-start w-full">
      <div class="w-16 mr-2">
        <toggle @switch="agree = $event" />
      </div>
      <p class="text-sm">
        I have <span v-if="!agree" class="text-red-500">not</span> read &
        <span v-if="!agree" class="text-red-500">do not</span> agree to the code
        of conduct
      </p>
    </div>
    <transition name="fade-up-left" appear>
      <div class="fixed right-5 bottom-16 w-60">
        <Button
          :type="agree ? 'primary' : 'disabled'"
          text="Begin Tutor Application"
          @click="agree? startTutorApplication(): ''"
        />
      </div>
    </transition>
  </credentials-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CredentialsLayout from "../components/base/credentialsLayout.vue";
import Button from "../components/button.vue";
import Toggle from "../components/toggle.vue";
import codeofconduct from "../components/codeofconduct.vue";
export default {
  computed: {
    ...mapState(["username", "user"])
  },
  components: {
    CredentialsLayout,
    Button,
    Toggle,
    codeofconduct,
  },
  created() {
    if (!!this.user && !!this.user.tutor_application) this.$router.replace(`/${this.username}/apply`);
  },
  data() {
    return {
      on: true,
      agree: false,
    };
  },
  methods: {
    ...mapActions(["start_application"]),
    async startTutorApplication() {
      await this.start_application();
      this.$router.push(`/${this.username}/apply`);
    },
  },
};
</script>
